/* General Container Styling */
/* .live-lifts {
    position: relative;
    width: 100%;
    height: 100%;
  }
   */

   .live-lifts {
    position: relative;
    width: 100%;
  }


  /* .live-lifts .lift-map {
    position: relative;
    z-index: 1;
  } */

  .live-lifts .lift-map {
    position: relative;
    width: 100%; /* or you can set a max-width if you want to limit it */
    /* height: 600px;  */
    /* Set the height to the desired value, or use a percentage */
  }

  #Lift_Lines {
    transform: translate(-0%,-27%);
  }


  
  .live-lifts .map-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  /* .live-lifts .map-container img {
    display: block;
    width: 100%;
    height: auto;
  } */

  .live-lifts .map-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Keeps aspect ratio intact */
    z-index: 1;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
  }

/* SVG container */
.live-lifts .lift-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none; /* Prevent clicks on the SVG */
  }
  /* .live-lifts .lift-lines svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
   */
   .live-lifts .lift-lines svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
/* 
  @media (min-width: 768px) {
    .live-lifts .lift-map {
      height: 500px;
    }
  } */
  
  /* @media (max-width: 768px) {
    .live-lifts .lift-map {
      height: 300px;
    }
  } */




/* Line Styling */
.live-lifts .lift-lines svg #Lift_Lines line,
.live-lifts .lift-lines svg #Lift_Lines polyline,
.live-lifts .lift-lines svg #Lift_Lines path {
  /* stroke: #dedc11; Default stroke color */
  stroke-width: 9; /* Default stroke width */
  fill: none;
}
  
  .live-lifts .lift-lines svg line.is-closed,
  .live-lifts .lift-lines svg polyline.is-closed,
  .live-lifts .lift-lines svg path.is-closed {
    /* stroke: var(--liftclosedbox);  */
    stroke: #bf2c37; 
    /* Red for closed lifts */
  }
  
  .live-lifts .lift-lines svg line.is-open,
  .live-lifts .lift-lines svg polyline.is-open,
  .live-lifts .lift-lines svg path.is-open {
    /* stroke: var(--liftopenbox);  */
    stroke: #8dc63f; 
    /* Green for open lifts */
  }
  
  .live-lifts .lift-lines svg line.is-standby,
  .live-lifts .lift-lines svg polyline.is-standby,
  .live-lifts .lift-lines svg path.is-standby {
    /* stroke: var(--liftonholdbox);  */
    stroke: #faa303; 
    /* Orange for standby lifts */
  }

  .live-lifts .lift-lines svg line.is-unknown,
  .live-lifts .lift-lines svg polyline.is-unknowny,
  .live-lifts .lift-lines svg path.is-unknown {
    stroke: #00000079; 
    /* Black/Grey for standby lifts */
  }
  
  /* Animations */
  @keyframes dash {
    to {
      stroke-dashoffset: -1000;
    }
  }
  
  @keyframes standbyBlink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

  /* line {
    stroke: red;
    stroke-width: 50px;
  } */


  .map-legend {
    display: none;
    position: absolute;
    bottom: 20px; /* Adjust the distance from the bottom */
    left: 20px; /* Adjust the distance from the left */
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background for the legend */
    padding: 10px;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .color-box {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .legend-item.open .color-box {
    /* background-color: var(--liftopenbox);  */
    background-color: #8dc63f; 
    /* Color for Open lifts */
  }
  
  .legend-item.closed .color-box {
    /* background-color: var(--liftclosedbox);  */
    background-color: #bf2c37; 
    /* Color for Closed lifts */
  }
  
  .legend-item.standby .color-box {
    /* background-color: var(--liftonholdbox);  */
    background-color: #faa303; 
    /* Color for Standby lifts */
  }
  .legend-item.unknown .color-box {
    background-color: #00000079; 
    /* Color for Standby lifts */
  }
  
  .legend-item span {
    color: #333; /* Text color */
  }