body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar {
  background-color: var(--color-secondary);
}
.navbar-item {
  color: var(--font-color);
}
.theme-lightold {
  /* --color-primary: #0060df; */
  --color-primary: #8fbcbb;
  --color-secondary: #fbfbfe;
  --color-accent: #fd6f53;
  --font-color: #000000;
  --sectionbox-bg: white;
  --sectiondivide: #DAE1E9;
  --liftopenbox: #1ac61a;
  --liftclosedbox: #ff4c4c;
  --liftonholdbox: orange;
  --searchboxbg: rgb(236, 239, 244);
  --searchboxtext: rgb(59, 66, 82);
  /* --liftonholdboxrgb: var(--liftonholdbox);
  --liftopenboxrgb: var(--liftopenbox);
  --liftclosedboxrgb: var(--liftclosedbox); */
}
/* .theme-light {
  --color-primary: #c2edce;
  --color-secondary: #f6f6f2;
  --color-accent: #fd6f53;
  --font-color: #388087;
  --sectionbox-bg: #fdfef7ce;
  --sectiondivide: #DAE1E9;
  --liftopenbox: #1ac61a;
  --liftclosedbox: #ff4c4c;
  --liftonholdbox: orange;
  --searchboxbg: rgb(236, 239, 244);
  --searchboxtext: rgb(59, 66, 82);

} */
.theme-light {
  --color-primary: #badfe7;
  --color-secondary: #f2f4f8;
  --color-accent: #fd6f53;
  --font-color: #4c566a;
  --sectionbox-bg: #ffffff;
  --sectiondivide: #dfe4ed;
  --liftopenbox: #a3be8c;
  --liftopenboxrgb: 46, 139, 87;
  --liftclosedbox: #bf616a;
  --liftclosedboxrgb: 178, 74, 74;
  --liftonholdbox: #d08770;
  --liftonholdboxrgb: 210, 105, 30;
  --searchboxbg: rgb(236, 239, 244);
  --searchboxtext: rgb(59, 66, 82);
  --box-shadow: rgba(184, 194, 215, 0.25) 0px 4px 6px, rgba(184, 194, 215, 0.1) 0px 5px 7px;
  --filterbutton-active: #6fB3bb;
}

.theme-dark {
  --color-primary: seagreen;
  --color-secondary: #242931;
  --color-accent: #8fbcbb;
  --font-color: #d8dee9;
  --sectionbox-bg: rgba(76,86,106,0.3);
  --sectiondivide: #DAE1E945;
  --liftopenbox: seagreen;
  --liftopenboxrgb: 46, 139, 87;
  --liftclosedbox: firebrick;
  --liftclosedboxrgb: 178, 74, 74;
  --liftonholdbox: chocolate;
  --liftonholdboxrgb: 210, 105, 30;
  --searchboxtext: rgb(236, 239, 244);
  --searchboxbg: rgb(59, 66, 82);
  --box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  --filterbutton-active: darkgreen;
}
.theme-darkold {
  --color-primary: #17ed90;
  --color-secondary: #243133;
  --color-accent: #12cdea;
  --font-color: #ffffff;
  --sectionbox-bg: white;
  --sectiondivide: #DAE1E9;
  --liftopenbox: seagreen;
  --liftclosedbox: firebrick;
  --liftonholdbox: chocolate;
}
.container {
  display: flex;
  width: 100%;
  /* height: 100%; */
  background: var(--color-secondary);
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
.container button {
  color: var(--font-color);
  background: var(--color-primary);
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
}



