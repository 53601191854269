/* .theme-light {
    --color-primary: #0060df;
    --color-secondary: #fbfbfe;
    --color-accent: #fd6f53;
    --font-color: #000000;
  }
  .theme-dark {
    --color-primary: #17ed90;
    --color-secondary: #243133;
    --color-accent: #12cdea;
    --font-color: #ffffff;
  } */

.today--section {
    margin-bottom: 10px;
    padding: 0 50px;
}
.today--section h2 {
    font-size: 20px;
}
.today--section__box {
    background-color: var(--sectionbox-bg);
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    /* color: #000000; */
}
.btc--section {
    text-align: center;
    border-right: 1px solid var(--sectiondivide);
}
.btc--section h5 {
    font-size: 30px;
}
.snow--section {
    text-align: center;
    border-right: 1px solid var(--sectiondivide);
}
.snow--section h5 {
    font-size: 30px;
}
.snowend--section {
    text-align: center;
}
.snowend--section h5 {
    font-size: 30px;
}
@media (max-width: 480px) {
    .snow--section {
        border-right: none;
    }
    .btc--section {
        border-right: none;
    }
    /* .today--section {
        margin-top:  50px;
    } */
}

@media only screen and (max-width: 600px) {
    .snow--section h5 {
      font-size: 5vw;
    }
    .snowend--section h5 {
      font-size: 5vw;
    }
    p {
      font-size: 2vw;
    }
    .today--section {
        padding: 0px 20px;
    }
    h6 {
        font-size: 2vw;
    }
    .column.snow--section {
        padding: 0px;
    }
    .column.snowend--section {
        padding: 0px;
    }
    #basetotal {
        margin-top: 5px;
    }
  }

