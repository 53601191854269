/* .theme-light {
    --color-primary: #0060df;
    --color-secondary: #fbfbfe;
    --color-accent: #fd6f53;
    --font-color: #000000;
  }
  .theme-dark {
    --color-primary: #17ed90;
    --color-secondary: #243133;
    --color-accent: #12cdea;
    --font-color: #ffffff;
  } */

.today--section {
    margin-bottom: 10px;
    padding: 0 50px;
}
.today--section h2 {
    font-size: 20px;
}
.today--section__box {
    background-color: var(--sectionbox-bg);
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    /* color: #000000; */
}
.btc--section {
    text-align: center;
    border-right: 1px solid var(--sectiondivide);
}
.btc--section h5 {
    font-size: 30px;
}
.lifts--section {
    text-align: left;
    border-right: 1px solid var(--sectiondivide);
}
.lifts--section h5 {
    font-size: 30px;
    padding-bottom: 10px;
}
.liftsend--section {
    text-align: left;
}
.liftsend--section h5 {
    font-size: 30px;
    padding-bottom: 10px;
}
th {
    font-weight: 400;
    font-size: 15px;
    padding: 5px 0px;
    border-bottom: #00000024;
    border-bottom-width: thin;
    border-bottom-style: solid;
    color: var(--font-color);
}
table th {
    color: var(--font-color);
}
table {
    border-collapse: separate;
    border-spacing: 5px;
}
/* #Closed {
    color: red;
}
#Open {
    color: green;
}
#OnHold {
    color: orange;
} */
#Open {
    color: var(--font-color);
    /* background: var(--liftopenbox); */
    background-color: rgba(var(--liftopenboxrgb), 0.3);
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    border: var(--liftopenbox) solid thin;
}
#Closed {
    color: var(--font-color);
    /* background: var(--liftclosedbox); */
    background-color: rgba(var(--liftclosedboxrgb), 0.3);
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    border: var(--liftclosedbox) solid thin;
}
#OnHold {
    color: var(--font-color);
    /* background: var(--liftonholdbox); */
    background-color: rgba(var(--liftonholdboxrgb), 0.3);
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    border: var(--liftonholdbox) solid thin;
}
#waittime {
    text-align: end;
}
#error {
    text-align: center;
}
#indicator {
    display: none;
}

#difficultyicon img{
    max-height: 1.5em;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(166, 166, 166, 0.5));
    filter: drop-shadow(0px 0px 4px rgba(166, 166, 166, 0.5));  
}
#difficultyicon {
    text-align: center;
}
#centerhead {
    text-align: center;
}

#searchbox{
    color: var(--searchboxtext);
    background: var(--searchboxbg);
    border: none;
}
thead th {
    font-weight: bold;
}


@media (max-width: 480px) {
    .lifts--section {
        border-right: none;
    }
    .btc--section {
        border-right: none;
    }
    /* .today--section {
        margin-top:  50px;
    } */
}


@media only screen and (max-width: 600px) {
    .lifts--section h5 {
      font-size: 5vw;
    }
    .liftsend--section h5 {
      font-size: 5vw;
    }
    p {
      font-size: 2vw;
    }
    .today--section {
        padding: 0px 20px;
    }
    h6 {
        font-size: 2vw;
    }
    ul {
        font-size: 2.2vw;
    }
    th {
        font-size: 2.1vw;
    }
    .today--section__box {
        padding: 20px 0px !important;
    }
    /* .status {
        display: none;
    }
    #indicator {
        display: block;
    } */
  }

@media only screen and (max-width: 810px) {
    #waittime {
        text-align: end;
        display: none;
    }
}