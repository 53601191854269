/* .topheader {
    background-color: #e63a36;
  } */
  .navbar {
    background-color: var(--color-secondary);
  }
  .navbar-item {
    color: var(--font-color);
    text-align: right;
  }
  .results--section {
    /* padding: 20px 0px; */
    margin-top: 10px;
  }
  h1 {
    text-align: center;
    font-size: 30px;
  }
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    color: var(--font-color);
    background: var(--color-secondary);
    overflow: visible;
  }
  /* .theme-light {
    --color-primary: #0060df;
    --color-secondary: #fbfbfe;
    --color-accent: #fd6f53;
    --font-color: #000000;
  }
  .theme-dark {
    --color-primary: #17ed90;
    --color-secondary: #243133;
    --color-accent: #12cdea;
    --font-color: #ffffff;
  } */

  .container {
    display: flex;
    width: 100%;
    /* height: 100%; */
    background: var(--color-secondary);
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  .container h1 {
    color: var(--font-color);
  }
  .container button {
    color: var(--font-color);
    background: var(--color-primary);
    padding: 10px 20px;
    border: 0;
    border-radius: 5px;
  }
  .theme.container {
    align-items: flex-end;
    padding-right: 50px;
}


@media only screen and (min-width: 300px) {
  .columns:not(.is-desktop) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .theme.container {
    padding-right: 20px;
}
}

.ClosedNotice {
  color: var(--font-color);
  /* background: var(--liftclosedbox); */
  background-color: rgba(var(--liftclosedboxrgb), 0.3);
  border-radius: 4px;
  padding: 20px;
  margin: 10px 0;
  text-align: center;
  vertical-align: middle;
  border: var(--liftclosedbox) solid thin;
}


.navbar-link {
  color: var(--font-color);
  text-align: right;
}

@media screen and (min-width: 1000px) {
  .navbar-item.has-dropdown.is-active .navbar-link, .navbar-item.has-dropdown:hover .navbar-link {
    background-color: var(--sectionbox-bg);
  }
}

@media screen and (min-width: 1000px) {
  .navbar-dropdown {
    background-color: var(--sectionbox-bg);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #dbdbdb;
    -webkit-box-shadow: 0 8px 8px rgba(10,10,10,.1);
    box-shadow: 0 8px 8px rgba(10,10,10,.1);
    display: none;
    font-size: .875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 20;
  }
}

.navbar-link.is-active, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:hover {
  background-color: #f5f5f5;
  color: var(--font-color);
}

@media screen and (min-width: 1000px) {
  .navbar-dropdown:hover {
    background-color: #f5f5f5;
    color: #0a0a0a;
  }
}

.navbar-link.is-active, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:hover {
  background-color: var(--sectionbox-bg);
  color: var(--font-color);
}



/* .gHTpwg {
  user-select: none;
  padding: 1em;
  cursor: pointer;
  pointer-events: auto;
  border: 0px;
  border-radius: 0.25em;
  background-color: transparent;
  transition: background-color 100ms ease-in-out;
  margin-left: 0.5em;
  position: relative;
  overflow: hidden;
} */

/* .gHTpwg:hover:not(:disabled) {
  background-color: rgba(76, 86, 106, 0.5);
}

.gHTpwg:active, .gHTpwg:focus, .gHTpwg:hover {
  outline: 0px;
} */

/* .gHTpwg > * {
  width: 1.125em;
  height: 1.125em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5625em;
  margin-left: -0.5625em;
} */

.Sun_Icon {
  fill: var(--font-color);
  transition: fill 400ms ease-in-out;
}

.Moon_Icon {
  fill: var(--font-color);
  transition: fill 400ms ease-in-out;
}

.navbar-item:hover {
background-color: var(--sectionbox-bg);
color: var(--font-color);
}

/* button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0px;
}

button, [type="button"], [type="reset"], [type="submit"] {
  appearance: button;
}

button, select {
  text-transform: none;
} */

.loading-indicator {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

}